import { TextProps, Text } from "@chakra-ui/react";
import React from "react";
import { body, heading } from "../../constants/fonts";

export interface IOpenPositionsTextProps extends TextProps {
  text: string;
  header?: boolean;
}

export const OpenPositionsText: React.FC<IOpenPositionsTextProps> = ({
  text,
  header,
  ...props
}) => {
  return (
    <Text
      fontFamily={header ? heading : body}
      fontSize={header ? "medium" : "small"}
      {...props}
    >
      {text}
    </Text>
  );
};
