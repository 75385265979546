import { Box, Flex } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { OpenPositionsText } from "../texts/OpenPositionsText";
import { Link } from "gatsby";
import { Button } from "../inputs/Button";

export interface IOpenPositionsCardProps {
  img: ReactNode;
  title: string;
  text: string;
  readMoreLink: string;
}

export const OpenPositionsCard: React.FC<IOpenPositionsCardProps> = ({
  img,
  text,
  title,
  readMoreLink,
}) => {
  return (
    <Flex
      flexDirection="column"
      background="white"
      color="black"
      gap="1rem"
      justifyItems="center"
      alignSelf="center"
      height={{ base: "30rem", lg: "30rem" }}
      width={{ base: "20rem", lg: "25rem" }}
    >
      {img}
      <OpenPositionsText textAlign="center" text={title} header />
      <OpenPositionsText
        textAlign="center"
        padding="0 3rem 1rem 3rem"
        text={text}
      />
      <Box alignSelf="center" paddingBottom="1rem">
        <Link to={readMoreLink}>
          <Button justifySelf="center" colorScheme="secondary">
            Read more
          </Button>
        </Link>
      </Box>
    </Flex>
  );
};
