import { Stack } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Section } from "../../layout/Section";
import { TextAndVisual } from "../../layout/TextAndVisual";
import { BodyText } from "../../texts/BodyText";
import { Hero } from "../../texts/Hero";
import { CircularImage } from "../../visuals/CircularImage";

export function CareersHero() {
  return (
    <Section>
      <TextAndVisual
        isVisualLeft
        textPart={
          <Stack spacing="5">
            <Hero>Looking for coders and IT experts with industrial experience!</Hero>
            <BodyText>
              Are you a senior software engineer or an IT professional with an industrial
              background looking for work-life balance AND interesting career opportunities?
              Is problem solving and marrying IT with business your passion too?
              Customer contact does not scare you? We might be a great fit for you!
            </BodyText>
          </Stack>
        }
        visualPart={
          <CircularImage adornment="top">
            <StaticImage
              src="../../../images/christoffer_and_julia_working.jpg"
              alt="Christoffer and Julia"
              placeholder="blurred" />
          </CircularImage>}
      />
    </Section>
  );
}