import { Flex, Stack } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Section } from "../../layout/Section";
import { TextAndVisual } from "../../layout/TextAndVisual";
import { BodyText } from "../../texts/BodyText";

export function Detail() {
  return (
    <Section>
      <Flex gap="6rem" flexDirection="column">
        <TextAndVisual
          isVisualLeft
          textPart={
            <Stack spacing="5">
              <BodyText>
                As a company, we are heading into our teen years and our
                business lies on a solid foundation. We offer our team members
                competitive salaries and benefits, great working tools, as well
                as career-advancing challenges and trainings, but above all we
                offer a great place to work. Working in the IT industry can be
                stressful in many ways, but we are building a company for us –
                our people. At Indevit, we all – our management included – works
                hands-on in our projects.
              </BodyText>
            </Stack>
          }
          visualPart={
            <StaticImage
              src="../../../images/jonathan_and_julia.jpg"
              alt="Jonathan and Julia"
              placeholder="blurred"
            />
          }
        />
        <TextAndVisual
          textPart={
            <Stack spacing="5">
              <BodyText>
                Being a non-hierarchical company means that we give our people a
                lot of responsibility and freedom. As an independent team
                member, you decide how you work on your projects, but you have
                our full support and experienced colleagues to juggle ideas
                with. We value your input, and we are offering you a spot in our
                future line up. We hope to hear from you!
              </BodyText>
            </Stack>
          }
          visualPart={
            <StaticImage
              src="../../../images/christoffer_and_jonathan_working.jpg"
              alt="Jonathan and Julia"
              placeholder="blurred"
            />
          }
        />
      </Flex>
    </Section>
  );
}
