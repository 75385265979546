import React from "react";
import { Layout } from "../components/layout/Layout";
import { MetaTags } from "../components/MetaTags";
import { CareersHero } from "../components/sections/careers/CareersHero";
import { Detail } from "../components/sections/careers/Detail";
import { Summary } from "../components/sections/careers/Summary";

function CareersPage() {
  return (
    <Layout heroSection={<CareersHero />} isHeroShort>
      <Summary />
      <Detail />
    </Layout>
  )
}

export default CareersPage;

export const Head = () => (
  <MetaTags title="Careers" />
)